body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.adult {
  color: maroon;
}

.list-group > .list-group-item {
  color: unset;
  background-color: unset;
}

.game-info {
  margin-left: 20px;
}

.row > .game-name {
  position: absolute;
  top: 0;
  right: 0;
  width: initial;
}

.container-fluid.card-container {
  padding: 0;
}

.clover-card {
  border: 2px #808080 solid;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 0;
  padding-left: 8px;
  padding-right: 8px;
  margin: 4px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  animation-duration: 0.5s;
}

.clover-card.card-0 {
  border-style: dashed;
}

.clover-card.selected {
  box-shadow: 0 0 0 0.2rem blue
}

/*
.clover-card.duplicated {
  color: #707070;
  border-color: #D0D0D0;
}
*/

.clover-card.correct-card,
.clover-card.correct-card.duplicated {
  border-size: 2px;
  border-color: #00FF00;
}

.correct-card-text {
  color: #00FF00;
}

.clover-card.correct-position,
.clover-card.correct-position.duplicated {
  border-size: 2px;
  border-color: #f5ed00;
}

.correct-position-text {
  color: #f5ed00;
}

.clover-card.correct-position-incorrect-rotation,
.clover-card.correct-position-incorrect-rotation.duplicated {
  border-size: 2px;
  border-color: #FF9933;
}

.correct-position-incorrect-rotation-text {
  color: #FF9933;
}

.clover-card.incorrect-card,
.clover-card.incorrect-card.duplicated {
  border-size: 2px;
  border-color: #B80000;
}

.incorrect-card-text {
  color: #B80000;
}

.column-clues .row {
  width: 100%;
  margin: 0;
}

.clover-card .card-internal-row {
  /*account for negative margin*/
  width: calc(100%);
  margin: 0;
}

.clover-card .card-internal-col {
  padding: 0;
}

.clover-card .word {
  margin-left: 0;
  margin-top: 0;
  padding: 4px;
  word-wrap: break-word;
}

.clover-card.clue-card .word {
  width: calc(50% - 4px);
}

.clover-card .shown-word {
  transition-property: left, right, top, bottom, transform;
  transition-timing-function: ease-out;
  /*transition-duration: inherit;*/
  transition-duration: 0.3s;
  transform: translateX(0) translateY(0);
  position: absolute;
  left: 0;
  right: 100%;
  top: 0;
  bottom: 100%;
  /*visibility: hidden;*/
  /*opacity: 50%;*/
  width: calc(50% - 4px);
  /*width: calc(50% - 4px - 15px);*/
  height: calc(50% - 4px);
  z-index: -1;
}

.clover-card .word.hidden-word {
  visibility: hidden;
  z-index: -1;
}

.clover-card .word.bold-word {
  font-weight: bolder;
}

.clover-card .word.right-word {
  text-align:right;
}

.clover-card .word.left-word.shown-word {
  left: 0;
  right: 100%;
}

.clover-card .word.top-word.shown-word {
  top: 0;
  bottom: 100%;
}

.clover-card .word.bottom-word.shown-word {
  top: 100%;
  bottom: 0;
  transform: translateX(0) translateY(calc(-100% - 4px));
  vertical-align: text-bottom;
}

.clover-card .word.right-word.shown-word {
  left: 100%;
  right: 0;
  transform: translateX(calc(-100% - 4px)) translateY(0);
  text-align:right;
}

.clover-card .word.right-word.bottom-word.shown-word {
  transform: translateX(calc(-100% - 4px))  translateY(calc(-100% - 4px));
}

.button-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-column .btn.btn-sm {
  margin: 4px;
  z-index: 1;
  font-size: 20px;
}

input.clue {
  text-align: right;
}

.clue {
  position: relative;
}

.clue div, .clue span,
.clue input {
  text-align: right;
  position: absolute;
  transform: translateY(-50%);
  right: -12px;
  border-color: grey;
  border-radius: 4px 0 0px 4px;
  border-right: 0;
  padding-right: 12px;
  padding-left: 8px;
}

.clue input {
  padding-top: 13px;
  padding-bottom: 14px;
}

.clue div, .clue span {
  border-width: 2px;
  border-style: solid;
  border-right: 0;
  padding-top: 12px;
  padding-bottom: 10px;
}

.clue input:active, 
.clue input:focus,
.clue input:focus-visible {
  box-shadow: -0.25rem 0 0 0.25rem rgba(13,110,253,.25);
}

.clue div {
  word-wrap: break-word;
  width: 100%;
}

img.loader {
   width:100%;
   max-width: 100px;
  display:block;
  margin:auto;
}
